<template>
  <div class="flex xs12">
    <local-table
      class="table-hover table-striped"
      :columns="planningFields"
      :data="planningList"
      :loading="loading || isLoading"
    >
      <template v-slot:top>
        <div class="flex xs12 md2 offset--md1">
          <va-button
            flat
            small
            color="success"
            icon="fa fa-file-excel-o"
            @click.prevent="prepareDownload()"
          >
            {{ $t('tables.actions.export') }}
          </va-button>
        </div>
      </template>
      <template v-slot:row-actions="{ props }">
        <va-popover
          :message="$t('tables.actions.view')"
          placement="left"
        >
          <va-button
            flat
            small
            color="primary"
            icon="fa fa-search-plus"
            @click.prevent="openModal(props.rowData)"
          />
        </va-popover>
        <va-popover
          :message="$t('tables.actions.delete')"
          placement="left"
        >
          <va-button
            flat
            small
            color="accent"
            icon="fa fa-times"
            @click.prevent="tryDelete(props.rowData)"
          />
        </va-popover>
      </template>
    </local-table>
    <va-modal
      v-model="viewModal"
      size="large"
      :hide-default-actions="true"
      :title="$t('layout.information')"
    >
      <div class="container-fluid">
        <div class="row">
          <div class="flex xs12">
            <h6>{{ $t('teams.inputs.languagesInput') }}</h6>
            <p
              v-for="language of currentLanguages"
              :key="language"
            >
              {{ language }}
            </p>
            <hr>
            <h6>{{ $t('teams.inputs.zoneDescriptionInput') }}</h6>
            <p>{{ currentPlan.zoneDescription }}</p>
            <hr>
            <h6>{{ $t('teams.inputs.equipementSelectInput') }}</h6>
            <img
              v-for="equipement of selectedEquipements"
              class="responsive"
              width="100px"
              :key="equipement.id"
              :alt="equipement.alt"
              :title="equipement.alt"
              :src="equipement.src"
            />
          </div>
        </div>
        <hr>
        <h5 class="mt-4 mb-4">{{ $t('teams.tabs.planning.modal') }}</h5>
        <div class="row">
          <div
            class="flex xs12 md6 lg4"
            v-for="month of currentPlan.monthly"
            :key="month.id"
          >
            <monthly-view
              :current-month="month.date"
              :remote-data="month"
            />
          </div>
        </div>
      </div>
    </va-modal>
  </div>
</template>
<script>
const LocalTable = () => import(/* webpackPrefetch: true */ '@/components/extras/DataTables/LocalTable')
const MonthlyView = () => import(/* webpackPrefetch: true */ '../inscription/MonthlyView')

export default {
  name: 'plan-history',
  components: {
    LocalTable, MonthlyView,
  },
  props: {
    teamId: {
      type: Number,
      default: 0,
    },
    loading: {
      type: Boolean,
      default: function () {
        return false
      },
    },
  },
  data () {
    return {
      months: [],
      planningList: [],
      currentPlan: {},
      selectedEquipements: [],
      currentLanguages: [],
      isLoading: false,
      viewModal: false,
    }
  },
  computed: {
    haveErrors () {
      return this.error.existent || this.error.leader || this.error.invalid
    },
    planningFields () {
      return [
        {
          name: 'year',
          title: this.$t('tables.headings.year'),
          type: 'number',
          width: '50px',
        },
        {
          name: 'submitter.name',
          title: this.$t('tables.headings.submitter'),
        },
        {
          name: 'zoneDescription',
          title: this.$t('tables.headings.zone_description'),
        },
        {
          name: 'presentationsPlanned',
          title: this.$t('tables.headings.presentations_planned'),
        },
        {
          name: '__slot:actions',
          visible: this.planningList.length > 0,
          dataClass: 'text-right',
          width: '20%',
        },
      ]
    },
    equipements () {
      const e = [
        {
          id: '1',
          src: '/img/equipements/full_equipement.jpg',
          alt: this.$t('teams.equipements.backpack'),
        }, {
          id: '2',
          src: '/img/equipements/semi_equipement.jpg',
          alt: this.$t('teams.equipements.solar'),
        }, {
          id: '3',
          src: '/img/equipements/large_equipement.jpg',
          alt: this.$t('teams.equipements.large'),
        }, {
          id: '5',
          src: '/img/equipements/tablet.jpeg',
          alt: this.$t('teams.equipements.handheld'),
        }, {
          id: '4',
          src: '/img/equipements/no_equipement.png',
          alt: this.$t('teams.equipements.none'),
        },
      ]
      return e
    },
  },
  watch: {
    teamId (val) {
      if (val === 0) return
      this.asyncFindPlanning()
    },
  },
  created () {
    this.asyncFindPlanning()
  },
  methods: {
    fixData (data) {
      data.forEach(u => {
        let count = 0
        if (Array.isArray(u.monthly)) {
          count = u.monthly.reduce((t, i) => {
            return t + i.presentations
          }, 0)
        }
        u.presentationsPlanned = count
      })
      return data
    },
    async asyncFindPlanning () {
      this.isLoading = true

      const route = '/teams/planning?team=' + this.teamId
      let response = false
      try {
        response = await this.$http.get(route)
      } catch (e) {
        // console.log('Error', e)
        this.isLoading = false
        return
      }

      this.planningList = this.fixData(response.data.data)
      this.isLoading = false
    },
    openModal (item) {
      this.currentPlan = Object.assign({}, item)
      this.setEquipements(this.currentPlan)
      this.setLanguages(this.currentPlan)
      this.viewModal = true
    },
    setEquipements (plan) {
      if (!plan.equipement) return
      this.selectedEquipements = []

      const equipements = plan.equipement.split(',')
      for (const equipement of equipements) {
        const val = this.equipements.find(e => e.id === equipement)
        if (val) {
          this.selectedEquipements.push(val)
        }
      }
    },
    setLanguages (plan) {
      if (!plan.languages) return
      this.currentLanguages = plan.languages.split(',')
    },
    async tryDelete (item) {
      const result = await this.$swal({
        icon: 'warning',
        text: this.$t('notifications.confirm.delete'),
        showCancelButton: true,
        confirmButtonText: this.$t('layout.buttons.confirm'),
        cancelButtonText: this.$t('layout.buttons.cancel'),
      })

      if (result.value) {
        try {
          await this.$http.delete('/teams/planning/' + item.id)
        } catch (e) {
          // console.log('Error while deleting')
        }

        this.planningList = this.planningList.filter(m => m.id !== item.id)
      }
    },
    prepareDownload () {
      const format = { data: { team: this.teamId }, format: 'xlsx' }
      const prepareRoute = 'teams/planning/download'
      const downloadRoute = 'teams/planning/download/'

      return this.downloadFile(format, prepareRoute, downloadRoute)
    },
    async downloadFile (filters, prepareRoute, downloadRoute) {
      let fileName = ''
      const prepare = {
        icon: 'info',
        title: this.$t('notifications.download.prepare'),
        confirmButtonText: this.$t('notifications.download.button'),
        text: this.$t('notifications.download.wait'),
        showLoaderOnConfirm: true,
        allowOutsideClick: () => !this.$swal.isLoading(),
        preConfirm: async () => {
          let data = false
          try {
            data = await this.$http.post(prepareRoute, filters)
          } catch (e) {
            this.$swal.insertQueueStep(error)
            return
          }

          fileName = data.data.data
          this.$swal.insertQueueStep(download)
        },
      }
      const error = {
        icon: 'error',
        title: 'Error',
        text: this.$t('notifications.download.error'),
      }
      const download = {
        icon: 'success',
        title: this.$t('notifications.download.downloading'),
        allowOutsideClick: () => !this.$swal.isLoading(),
        onOpen: async () => {
          this.$swal.showLoading()
          let response = false
          try {
            response = await this.$http.get(downloadRoute + fileName, {
              responseType: 'blob',
            })
          } catch (e) {
            // console.log('Cant download file', e)
            await this.$swal.queue([retry])
            return
          }

          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', fileName)
          document.body.appendChild(link)
          link.click()

          this.$swal.close()
        },
      }
      const retry = {
        icon: 'error',
        title: 'Error',
        text: this.$t('notifications.download.failed'),
        confirmButtonText: this.$t('notifications.download.retry'),
        allowOutsideClick: true,
        preConfirm: () => {
          this.$swal.insertQueueStep(download)
        },
      }

      this.$swal.queue([prepare])
    },
  },
}
</script>
